import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import io from 'socket.io-client';

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const mySwal = withReactContent(Swal);

const TerminalGuide = () => {
    const history = useHistory();
    const location = useLocation();
    const { t } = useTranslation();

    const [timeLeft, setTimeLeft] = useState(120); // 2 minutes in seconds

    useEffect(() => {
        if (location.state && location.state.transactionId) {
            const socket = io({ auth: { transactionId: location.state.transactionId } });
            socket.on("terminalPayment", (data) => {
                if (data.transactionStatus === "failed") {
                    mySwal.fire(t("Error"), t("Payment Failed"), "error");
                    history.push('/')
                } else {
                    history.push("/thankyou");
                }
            });
        }
    }, [location.state, history]);

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft((prevTime) => {
                if (prevTime <= 1) {
                    clearInterval(timer);
                    history.push("/welcome");
                }
                return prevTime - 1;
            });
        }, 1000); // Decrease time every second

        return () => clearInterval(timer);
    }, [history]);

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    };

    return (
        <div id="setting-page" className="user-faq">
            <div id="setting-main">
                <div id="user-header">
                    <h5>
                        <i onClick={() => history.push("/")} className="bi bi-chevron-left me-3"></i>
                        <strong>{t("Terminal Payment")}</strong>
                    </h5>
                </div>

                <div id="user-body">
                    <h4 className="pt-3 ps-3">{t("How to pay using the terminal?")}</h4>
                    <div className="px-3 py-3 d-flex">
                        <div className="align-content-center ps-2">
                            <h5>{t("Time left to complete the process:")} {formatTime(timeLeft)}</h5>
                        </div>
                    </div>

                    {/* Step instructions for using the terminal */}
                    <div className="px-3 py-3 d-flex">
                        <div className="align-content-center ps-2">
                            <h5>01 {t("Tap your card on the terminal")}</h5>
                            <p>{t("Place your card near the terminal to initiate payment.")}</p>
                        </div>
                    </div>

                    <div className="px-3 py-3 d-flex">
                        <div className="align-content-center ps-2">
                            <h5>02 {t("Confirm payment")}</h5>
                            <p>{t("Wait for the confirmation on the terminal screen.")}</p>
                        </div>
                    </div>

                    <div className="px-3 py-3 d-flex">
                        <div className="align-content-center ps-2">
                            <h5>03 {t("Complete the payment")}</h5>
                            <p>{t("The terminal will process the payment and show a confirmation.")}</p>
                        </div>
                    </div>

                    <div className="px-3 py-3 d-flex">
                        <div className="align-content-center ps-2">
                            <h5>04 {t("Receive confirmation")}</h5>
                            <p>{t("A payment confirmation will appear once the transaction is complete and you will be redirected to another page.")}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TerminalGuide;
