import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

// import PaymentDone from '../../../../assets/images/logo/PaymentDone.png';
import WasherIcon from '../../../../assets/images/logo/PaymentWasherLogo.png';
import DryerIcon from '../../../../assets/images/logo/DryerPaymentLogo.png';
import VendingIcon from '../../../../assets/images/logo/VendingLogo.png';

import { currency, logo1Controller } from '../../../../dynamicController';

const mySwal = withReactContent(Swal);

const ThankYou = ({ transactionId, themeLogo, token }) => {
    const { t } = useTranslation();
    const [data, setData] = useState(null);
    const [stamp, setStamp] = useState(false);
    const [stampVoucherSetting, setStampVoucherSetting] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [loading, setLoading] = useState(false);
    const [qrCode, setQrCode] = useState(null);

    const history = useHistory();

    useEffect(() => {
        let timeoutId;
        let lastInteractionTime = Date.now();

        const checkInactivity = () => {
            const currentTime = Date.now();
            if (currentTime - lastInteractionTime > 55000) {
                history.push('/kioskv2');
            } else {
                timeoutId = setTimeout(checkInactivity, 55000 - (currentTime - lastInteractionTime));
            }
        };

        const handleUserInteraction = () => {
            lastInteractionTime = Date.now();
        };

        timeoutId = setTimeout(checkInactivity, 55000);

        window.addEventListener('mousemove', handleUserInteraction);
        window.addEventListener('keydown', handleUserInteraction);
        window.addEventListener('touchstart', handleUserInteraction);

        return () => {
            clearTimeout(timeoutId);
            window.removeEventListener('mousemove', handleUserInteraction);
            window.removeEventListener('keydown', handleUserInteraction);
            window.removeEventListener('touchstart', handleUserInteraction);
        };
    }, [history]);

    useEffect(() => {
        if (transactionId && token) {
            setLoading(true)
            axios
                .post('/api/kiosk/transactionDetails', { transactionId, token })
                .then((res) => {
                    const fetchedData = res.data.data;
                    setData(fetchedData);

                    const user = fetchedData.transactionDetails.user;
                    if ((!user || (!user.verified && !user.email_verified)) && fetchedData.qr) {
                        setQrCode(fetchedData.qr);
                    }

                    setLoading(false)

                    const paymentAmount = fetchedData.transactionDetails.payment_amount;
                    const machineType = fetchedData.transactionDetails.machine_type;
                    const voucherSettings = fetchedData.voucherSetting;

                    if (paymentAmount >= fetchedData.stamp_transaction_amount) {
                        const washerVoucherSetting = voucherSettings.find(
                            (setting) => setting.type === 'Washer Stamp'
                        );
                        const dryerVoucherSetting = voucherSettings.find(
                            (setting) => setting.type === 'Dryer Stamp'
                        );
                        const combinedVoucherSetting = voucherSettings.find(
                            (setting) => setting.type === 'Combined Stamp'
                        );

                        if (fetchedData.combined) {
                            if (combinedVoucherSetting?.voucher_eligible) {
                                setStampVoucherSetting(combinedVoucherSetting);
                                setStamp(true);
                            }
                        } else if (fetchedData.specific_stamp === 'both') {
                            if (machineType === 'Washer' && washerVoucherSetting?.voucher_eligible) {
                                setStampVoucherSetting(washerVoucherSetting);
                                setStamp(true);
                            } else if (machineType === 'Dryer' && dryerVoucherSetting?.voucher_eligible) {
                                setStampVoucherSetting(dryerVoucherSetting);
                                setStamp(true);
                            }
                        } else if (fetchedData.specific_stamp === 'washerOnly') {
                            if (machineType === 'Washer' && washerVoucherSetting?.voucher_eligible) {
                                setStampVoucherSetting(washerVoucherSetting);
                                setStamp(true);
                            }
                        } else if (fetchedData.specific_stamp === 'dryerOnly') {
                            if (machineType === 'Dryer' && dryerVoucherSetting?.voucher_eligible) {
                                setStampVoucherSetting(dryerVoucherSetting);
                                setStamp(true);
                            }
                        } else {
                            setStamp(false);
                        }
                    } else {
                        setStamp(false);
                    }
                })
                .catch((err) => {
                    setLoading(false)
                    mySwal.fire(t("Error"), t(err.response.data.error), "error");
                })
        }
    }, [transactionId, token, t]);

    const getMachineImage = (type) => {
        switch (type) {
            case 'Washer':
                return WasherIcon;
            case 'Dryer':
                return DryerIcon;
            case 'Vending Machine':
                return VendingIcon;
            default:
                return null;
        }
    };

    return (
        <div
            id="thank-you-screens"
            className="d-flex flex-column vh-100 secondscreen position-relative"
            style={{ overflowY: 'auto', overflowX: 'hidden' }}
        >
            <header className="d-flex flex-column w-100 p-3">
                <div className="d-flex justify-content-between align-items-start w-100">
                    <div className="d-flex flex-column align-items-start">
                        <div className="d-flex align-items-center">
                            <img
                                src={logo1Controller(themeLogo)}
                                alt="main logo"
                                className="img-fluid"
                                style={{ maxWidth: '200px', maxHeight: '70px' }}
                            />
                        </div>
                        {data && (
                            <h2 className="fs-4 fw-bold mt-2 text-color w-100">
                                {data.transactionDetails?.outlet?.outlet_name || ''}
                            </h2>
                        )}
                    </div>
                    <div className="d-flex flex-column align-items-end">
                        <div className="d-flex">
                            <i
                                className="bi bi-house-fill me-3 text-color"
                                style={{ height: '50px', fontSize: '50px', cursor: 'pointer' }}
                                onClick={() => history.push('/kioskv2')}
                            ></i>
                        </div>
                    </div>
                </div>
            </header>

            <main className="d-flex flex-column align-items-center justify-content-center w-100 px-3 flex-grow-1 text-center position-relative">
                <div className="mb-4 d-flex flex-column align-items-center" style={{ marginTop: '-50px' }}>
                    {/* <img
                        src={PaymentDone}
                        alt="Payment Done"
                        className="img-fluid mb-3"
                        style={{ height: '120px' }}
                    /> */}
                    <h2 className="text-color fw-bold">{t('Thank you for payment!')}</h2>
                </div>

                {data && (data.transactionDetails.machine_type === 'Washer' ||
                    data.transactionDetails.machine_type === 'Dryer') &&
                    stamp &&
                    stampVoucherSetting && (
                        <div
                            className="alert alert-primary text-center"
                            style={{ maxWidth: '600px', marginBottom: '1.5rem' }}
                        >
                            {data.transactionDetails.userId ? (
                                <>
                                    <p className="mb-1 fw-bold text-color">
                                        {data.transactionDetails.machine_type === 'Washer'
                                            ? t("You've earned 1 e-stamp for your wash!")
                                            : t("You've earned 1 e-stamp for your dry!")}
                                    </p>
                                    <p className="mb-0 text-color">
                                        {data.combined
                                            ? stampVoucherSetting.voucher_type === 'Flat'
                                                ? t(
                                                    'Collect 10 e-stamps Wash & Dry to earn FREE {{ amount }} credits voucher.',
                                                    {
                                                        amount: stampVoucherSetting.voucher_amount,
                                                    }
                                                )
                                                : t(
                                                    'Collect 10 e-stamps Wash & Dry to earn a {{ amount }}% voucher.',
                                                    {
                                                        amount: stampVoucherSetting.voucher_amount,
                                                    }
                                                )
                                            : data.transactionDetails.machine_type === 'Washer'
                                                ? stampVoucherSetting.voucher_type === 'Flat'
                                                    ? t(
                                                        'Collect 10 e-stamps Wash to earn FREE {{ amount }} credits voucher.',
                                                        {
                                                            amount: stampVoucherSetting.voucher_amount,
                                                        }
                                                    )
                                                    : t(
                                                        'Collect 10 e-stamps Wash to earn a {{ amount }}% voucher.',
                                                        {
                                                            amount: stampVoucherSetting.voucher_amount,
                                                        }
                                                    )
                                                : stampVoucherSetting.voucher_type === 'Flat'
                                                    ? t(
                                                        'Collect 10 e-stamps Dry to earn FREE {{ amount }} credits voucher.',
                                                        {
                                                            amount: stampVoucherSetting.voucher_amount,
                                                        }
                                                    )
                                                    : t(
                                                        'Collect 10 e-stamps Dry to earn a {{ amount }}% voucher.',
                                                        {
                                                            amount: stampVoucherSetting.voucher_amount,
                                                        }
                                                    )}
                                    </p>
                                </>
                            ) : (
                                <>
                                    <p className="mb-1 fw-bold text-color">
                                        {data.transactionDetails.machine_type === 'Washer'
                                            ? t("You've missed 1 e-stamp for your wash!")
                                            : t("You've missed 1 e-stamp for your dry!")}
                                    </p>
                                    <p className="mb-0 text-color">
                                        {data.combined
                                            ? stampVoucherSetting.voucher_type === 'Flat'
                                                ? t(
                                                    'Sign Up & Collect 10 e-stamps Wash & Dry to earn FREE {{ amount }} credits voucher.',
                                                    {
                                                        amount: stampVoucherSetting.voucher_amount,
                                                    }
                                                )
                                                : t(
                                                    'Sign Up & Collect 10 e-stamps Wash & Dry to earn a {{ amount }}% voucher.',
                                                    {
                                                        amount: stampVoucherSetting.voucher_amount,
                                                    }
                                                )
                                            : data.transactionDetails.machine_type === 'Washer'
                                                ? stampVoucherSetting.voucher_type === 'Flat'
                                                    ? t(
                                                        'Sign Up & Collect 10 e-stamps Wash to earn FREE {{ amount }} credits voucher.',
                                                        {
                                                            amount: stampVoucherSetting.voucher_amount,
                                                        }
                                                    )
                                                    : t(
                                                        'Sign Up & Collect 10 e-stamps Wash to earn a {{ amount }}% voucher.',
                                                        {
                                                            amount: stampVoucherSetting.voucher_amount,
                                                        }
                                                    )
                                                : stampVoucherSetting.voucher_type === 'Flat'
                                                    ? t(
                                                        'Sign Up & Collect 10 e-stamps Dry to earn FREE {{ amount }} credits voucher.',
                                                        {
                                                            amount: stampVoucherSetting.voucher_amount,
                                                        }
                                                    )
                                                    : t(
                                                        'Sign Up & Collect 10 e-stamps Dry to earn a {{ amount }}% voucher.',
                                                        {
                                                            amount: stampVoucherSetting.voucher_amount,
                                                        }
                                                    )}
                                    </p>
                                </>
                            )}
                        </div>
                    )}

                {
                    data ? (
                        <div className="d-flex flex-column align-items-center" style={{ maxWidth: '800px', width: '100%' }}>
                            <p className="text-color fw-bold mb-2">{t('Your machine is')}</p>
                            <div className="position-relative">
                                <img
                                    src={getMachineImage(data.transactionDetails.machine_type)}
                                    alt={data.transactionDetails.machine_type}
                                    style={{
                                        width: '150px',
                                        height: '110px',
                                        objectFit: 'contain',
                                    }}
                                />
                                <span
                                    className="position-absolute translate-middle"
                                    style={{
                                        fontSize: '1.5rem',
                                        fontWeight: 'bold',
                                        color: '#000',
                                        top: '55%',
                                        left: '50%',
                                    }}
                                >
                                    {data.transactionDetails.machine_name.split('-')[1]}
                                </span>
                            </div>

                            {(data.transactionDetails.machine_type === 'Washer' || data.transactionDetails.machine_type === 'Dryer') && (
                                <button
                                    type="button"
                                    className="btn btn-outline-primary rounded-pill mt-3 kiosk-button"
                                    style={{
                                        backgroundColor: '#e0f0ff',
                                        borderWidth: '3px',
                                        width: '220px',
                                        height: '80px',
                                        color: 'black',
                                    }}
                                >
                                    <div className="d-flex flex-column align-items-center justify-content-center w-100 h-100">
                                        {data.transactionDetails.machine_type === 'Washer' ? <span><strong>{t(data.transactionDetails.mode)}</strong></span> : null}
                                        <span>
                                            <strong>{data.transactionDetails.machine_duration} {t('minutes')}</strong>
                                        </span>
                                        <span>
                                            <strong>{currency(data.country)} {data.transactionDetails.payment_amount}</strong>
                                        </span>
                                    </div>
                                </button>
                            )}
                        </div>
                    ) : null
                }

                {qrCode && (
                    <div className="position-absolute" style={{ top: '50%', right: '10%', transform: 'translateY(-50%)' }}>
                        <p className="mb-2 text-color fw-bold text-center">{t('Scan to register as a member')}</p>
                        <img
                            src={qrCode}
                            alt="QR Code"
                            style={{
                                width: '150px',
                                height: '150px',
                            }}
                        />
                    </div>
                )}

                {data && data.transactionDetails.machine_type === 'Vending Machine' ? (
                    <p className="text-muted mt-3 text-color">
                        {t('Please select your items at the vending machine')}
                    </p>
                ) : (
                    <p className="text-muted mt-3 text-color">
                        {t('Please choose the temperature on the machine and press')}{' '}
                        <span className="text-danger fw-bold">{t('START')}</span>.
                    </p>
                )}
            </main>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        transactionId: state.payment.transactionId,
        themeLogo: state.theme.logo,
        token: state.kiosk.token,
    };
};

export default connect(mapStateToProps, null)(ThankYou);