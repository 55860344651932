import React, { useState, useEffect, useRef } from "react";

import washer1 from "../../../../assets/images/machines/washer1.png";
import washer2 from "../../../../assets/images/machines/washer2.png";
import washer3 from "../../../../assets/images/machines/washer3.png";
import washer4 from "../../../../assets/images/machines/washer4.png";
import promotionBanner from "../../../../assets/images/logo/promotion.png";

// cleanpro icon
import temp from "../../../../assets/images/icons/icn-temperature.png";
import insufficientToken from "../../../../assets/images/icons/icn-creditInsufficient.png";
import ticket from "../../../../assets/images/icons/icn-voucher.png";
import creditBear from "../../../../assets/images/icons/icn-creditGrey.png";
import cleanproCreditIcon from "../../../../assets/images/icons/icn-credit.png";
import voucherIcon from "../../../../assets/images/navIcons/icn-voucher.png";

// washup icon
import washupTemperature from "../../../../assets/images/icons/washup-temperature.png";

import axios from "axios";
import { useTranslation, Trans } from "react-i18next";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { currency } from "../../../../dynamicController";

import { Modal } from "antd";

const mySwal = withReactContent(Swal);
const STORETYPE = process.env.REACT_APP_STORETYPE;

const WasherPayment = ({ machine, user, history, outlet, saveTransactionId, saveTransactionType, theme, setDuration, token }) => {
  // STATES
  const [amount, setAmount] = useState(0);
  const [isOpen, setOpen] = useState(false);
  const [voucherId, setVoucherId] = useState(null);
  const [discountCode, setDiscountCode] = useState("");
  const [discountCodeInput, setDiscountCodeInput] = useState("");
  const [discountCodeModal, setDiscountCodeModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [promotion, setPromotion] = useState(false);
  const [id, setId] = useState(null);
  const [notificationType, setNotificationType] = useState(STORETYPE === "cleanpro" && machine.notification && !machine.running ? "Email" : "");
  const [email, setEmail] = useState(user && user.email ? user.email : "");
  const [number, setNumber] = useState(user && user.number ? user.number : "")
  const [razerBody, setRazerBody] = useState(null);
  const [ghlBody, setGhlBody] = useState(null);
  const [modeSelection, setModeSelection] = useState([]);
  const [mode, setMode] = useState("");
  const [discountAmount, setDiscountAmount] = useState(null)
  const [point, setPoint] = useState(false);
  const [laundryPassOption, setLaundryPassOption] = useState(false);
  const [laundryPass, setLaundryPass] = useState(false);
  const [laundryPassRule, setLaundryPassRule] = useState([]);
  const [paymentMethodModal, setPaymentMethodModal] = useState(false)
  const [voucherReminderModal, setVoucherReminderModal] = useState(false)
  const [paymentConfirmationModal, setPaymentConfirmationModal] = useState(false)
  const [paymentConfirmationText, setPaymentConfirmationText] = useState("")
  const [insufficientTokenModal, setInsufficientTokenModal] = useState(false)
  const [paymentMethod, setPaymentMethod] = useState("")

  const razerButtonClick = useRef();
  const ghlButtonClick = useRef();

  const { t } = useTranslation();

  useEffect(() => {
    if (razerBody) {
      razerButtonClick.current.click();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [razerBody]);

  useEffect(() => {
    if (ghlBody) {
      ghlButtonClick.current.click();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ghlBody]);

  useEffect(() => {
    if (machine.priceData.length > 0) {
      // sort the mode sequence according to price
      const sortedMode = machine.priceData.sort((a, b) => a.price - b.price)
      setModeSelection(sortedMode)

      const defaultmode = machine.priceData[0].defaultmode
      const defaultmodePrice = machine.priceData.filter(data => data.name === defaultmode)[0]
      if (defaultmodePrice) {
        setAmount(parseFloat(defaultmodePrice.price))
        setMode(defaultmode)
      } else {
        // just in case the defaultmode cannot find in priceData, take lowest price as defaultmode
        const lowestPrice = sortedMode[0]
        setAmount(parseFloat(lowestPrice.price))
        setMode(lowestPrice.name)
      }

      if ((user && machine.promo.status) || (!user && machine.non_member_promotion && machine.promo.status)) {
        // promotion
        const promoPrice = machine.promo.priceData.filter(data => data.mode === defaultmode)[0]
        const normalPrice = machine.priceData.filter(data => data.name === defaultmode)[0] // to prevent the defaultmode can find in promo but cannot find in normal priceData
        if (promoPrice && normalPrice) {
          setPromotion(true)
          setAmount(parseFloat(promoPrice.price))
          setMode(promoPrice.mode)
          if (promoPrice.washerRate || promoPrice.washerRate === 0) {
            // rate promotion
            setDiscountAmount({ type: "Rate", amount: parseFloat(promoPrice.washerRate) })
            if (promoPrice.washerRate === 0) {
              setPromotion(false)
            }
          } else if (promoPrice.discountedPrice || promoPrice.discountedPrice === 0) {
            // flat promotion
            setDiscountAmount({ type: "Flat", amount: (parseFloat(promoPrice.price) - parseFloat(promoPrice.discountedPrice)) })
            if ((parseFloat(promoPrice.price) - parseFloat(promoPrice.discountedPrice)) === 0) {
              setPromotion(false)
            }
          }
        } else {
          const lowestPromoPrice = machine.promo.priceData.filter(data => data.mode === sortedMode[0].name)[0]
          if (lowestPromoPrice) {
            setPromotion(true)
            setAmount(parseFloat(lowestPromoPrice.price))
            setMode(lowestPromoPrice.mode)
            if (lowestPromoPrice.washerRate || lowestPromoPrice.washerRate === 0) {
              // rate promotion
              setDiscountAmount({ type: "Rate", amount: parseFloat(lowestPromoPrice.washerRate) })
              if (lowestPromoPrice.washerRate === 0) {
                setPromotion(false)
              }
            } else if (lowestPromoPrice.discountedPrice || lowestPromoPrice.discountedPrice === 0) {
              // flat promotion
              setDiscountAmount({ type: "Flat", amount: (parseFloat(lowestPromoPrice.price) - parseFloat(lowestPromoPrice.discountedPrice)) })
              if ((parseFloat(lowestPromoPrice.price) - parseFloat(lowestPromoPrice.discountedPrice)) === 0) {
                setPromotion(false)
              }
            }
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user) {
      if (user.subscriptions.length > 0) {
        let washerSubscription = user.subscriptions.filter(subscription => subscription.washer_use && !subscription.washer_used_date)
        if (washerSubscription.length > 0) {
          setLaundryPassOption(true)
          setLaundryPassRule(washerSubscription[0].rules)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  // FUNCTIONS
  const buttonStyle = (m) => {
    if (STORETYPE === "washup" && mode === m) {
      return "selected"
    } else if (mode === m) {
      return "selected-temp";
    } else {
      return ""
    }
  };

  const discountText = () => {
    if (discountAmount) {
      if (discountAmount.type === "Flat") {
        if (STORETYPE === "cleanpro" && user && user.tokenSetting) {
          return `${discountAmount.amount} ${t("credit")}`
        } else {
          return `${currency(machine.country)} ${discountAmount.amount}`
        }
      } else {
        return `${discountAmount.amount} %`
      }
    }
  };

  const getPaymentAmount = (type) => {
    let payAmount = parseFloat(amount);
    let pointAmount = 0;

    if (discountAmount) {
      // promotion / voucher / discount code
      if (discountAmount.type === "Flat") {
        payAmount = parseFloat(amount) - parseFloat(discountAmount.amount);
      } else {
        const rate = (parseFloat(amount) * parseFloat(discountAmount.amount)) / 100;
        payAmount = (parseFloat(amount) - rate).toFixed(2);
      }
    }

    if (laundryPass) {
      payAmount = 0
    }

    if (payAmount <= 0) {
      payAmount = 0;
    }

    // point
    if (point) {
      if (parseFloat(user.point) >= (parseFloat(payAmount) * parseFloat(user.point_currency))) {
        // if user has sufficient points to pay (all amount paid using points)
        pointAmount = parseFloat(payAmount) * parseFloat(user.point_currency);
        payAmount = 0;
      } else {
        // use up all the current points and the user still needs to pay for the remaining amount
        pointAmount = parseFloat(user.point);
        payAmount = parseFloat(payAmount) - (parseFloat(user.point) / parseFloat(user.point_currency));
      }
    }

    if (type === "paymentAmount") {
      // machine payment amount
      return /\./g.test(payAmount.toString()) ? parseFloat(payAmount).toFixed(2) : payAmount;
    } else if (type === "totalPaymentAmount") {
      // payment amount + sms amount
      if (notificationType === "SMS" && !(user && machine.member_free_sms)) {
        payAmount = parseFloat(payAmount) + parseFloat(machine.sms_notification_amount);
      }
      return /\./g.test(payAmount.toString()) ? parseFloat(payAmount).toFixed(2) : payAmount;
    } else if (type === "pointAmount") {
      // point amount used for discount
      return pointAmount;
    } else if (type === "pointValue") {
      // point amount used for discount in RM
      return /\./g.test((parseFloat(pointAmount) / parseFloat(user.point_currency)).toString()) ?
        (parseFloat(pointAmount) / parseFloat(user.point_currency)).toFixed(2) :
        (parseFloat(pointAmount) / parseFloat(user.point_currency));
    } else if (type === "sms") {
      if (user && machine.member_free_sms) {
        return 0;
      } else {
        return /\./g.test(machine.sms_notification_amount.toString()) ?
          parseFloat(machine.sms_notification_amount).toFixed(2) :
          machine.sms_notification_amount;
      }
    }
  }

  const checkDiscountCode = (e) => {
    e.preventDefault();

    if (user && !machine.promo.status) {
      setLoading(true);
      axios
        .post("/api/user/checkDiscountCode", {
          discountCode: discountCodeInput,
          outletId: machine.outletId,
          machineType: machine.type,
          userId: user.id,
        })
        .then((res) => {
          let typ = res.data.discountType;
          let amt = parseFloat(res.data.discountAmount);
          setDiscountCode(discountCodeInput);
          setDiscountAmount({ type: typ, amount: amt })
          setVoucherId(null);
          setDiscountCodeModal(false);
          setLoading(false);
        })
        .catch((err) => {
          setDiscountCodeInput("");
          setLoading(false);
          mySwal.fire(t("Error"), t(err.response.data.error), "error");
        });
    }
  };

  const useVoucher = (e) => {
    if (id) {
      let foundVoucher = user.vouchers.filter((v) => v.id === id)[0];
      if (user && !machine.promo.status) {
        setDiscountAmount({ type: foundVoucher.type, amount: foundVoucher.amount })
        setVoucherId(foundVoucher.id);
        setOpen(false);
      }
    }
  };

  const chooseVoucher = (typ, amt, id) => {
    if (user && !machine.promo.status) {
      setDiscountAmount({ type: typ, amount: amt })
      setVoucherId(id);
      setOpen(false);
    }
  };

  const removeVoucher = () => {
    setDiscountAmount(null)
    setOpen(false);
    setVoucherId(null);
    setDiscountCode("");
    setDiscountCodeInput("");
    setDiscountCodeModal(false);
  };

  const renderDuration = () => {
    const currentPrice = machine.priceData.filter(data => data.name === mode)[0]
    if (currentPrice) {
      return currentPrice.runtime || 23
    } else {
      return 23
    }
  }

  useEffect(() => {
    setDuration(renderDuration());
  }, [renderDuration]);

  const changeMode = (m) => {
    if ((user && machine.promo.status) || (!user && machine.non_member_promotion && machine.promo.status)) {
      // promotion
      const promoPrice = machine.promo.priceData.filter(data => data.mode === m)[0]
      if (promoPrice) {
        setPromotion(true)
        setAmount(parseFloat(promoPrice.price))
        setMode(promoPrice.mode)
        if (promoPrice.washerRate || promoPrice.washerRate === 0) {
          // rate promotion
          setDiscountAmount({ type: "Rate", amount: parseFloat(promoPrice.washerRate) })
          if (promoPrice.washerRate === 0) {
            setPromotion(false)
          }
        } else if (promoPrice.discountedPrice || promoPrice.discountedPrice === 0) {
          // flat promotion
          setDiscountAmount({ type: "Flat", amount: (parseFloat(promoPrice.price) - parseFloat(promoPrice.discountedPrice)) })
          if ((parseFloat(promoPrice.price) - parseFloat(promoPrice.discountedPrice)) === 0) {
            setPromotion(false)
          }
        }
      } else {
        // cannot find the mode in promotion pricing, use normal pricing
        const normalPrice = machine.priceData.filter(data => data.name === m)[0]
        setAmount(normalPrice.price)
        setMode(normalPrice.name)
        setPromotion(false)
        setDiscountAmount(null)
      }
    } else {
      const normalPrice = machine.priceData.filter(data => data.name === m)[0]
      setAmount(normalPrice.price)
      setMode(normalPrice.name)
    }
  };

  const minusAmount = () => {
    const currentModeIndex = modeSelection.findIndex(data => data.name === mode)
    if (machine.isWashup) {
      // no step, fix to mode price
      if (currentModeIndex > 0) {
        const newMode = modeSelection[currentModeIndex - 1].name
        changeMode(newMode)
      }
    } else {
      const step = parseFloat(machine.step || 1)
      if (currentModeIndex > 0) {
        const newMode = modeSelection[currentModeIndex - 1].name
        const newModePrice = machine.priceData.filter(data => data.name === newMode)[0]
        if (newModePrice.price === amount) { // if 2 mode with same price
          changeMode(newMode)
          return;
        }
      }

      const newAmount = parseFloat(amount) - step
      if (newAmount >= step) {
        const newPrice = machine.priceData.filter(data => data.price === newAmount)
        if (newPrice.length <= 0) {
          // price not found in priceData, mode: OTHER
          setAmount(newAmount)
          setMode("OTHER")
          setPromotion(false)
          if (machine.promo.status) {
            setDiscountAmount(null)
          }
        } else if (newPrice.length === 1) {
          // only 1 mode with that price
          setAmount(newPrice[0].price)
          setMode(newPrice[0].name)
          setPromotion(false)
          if ((user && machine.promo.status) || (!user && machine.non_member_promotion && machine.promo.status)) {
            // promotion
            const promoPrice = machine.promo.priceData.filter(data => data.mode === newPrice[0].name)[0]
            if (promoPrice) {
              setPromotion(true)
              setAmount(parseFloat(promoPrice.price))
              setMode(promoPrice.mode)
              if (promoPrice.washerRate || promoPrice.washerRate === 0) {
                // rate promotion
                setDiscountAmount({ type: "Rate", amount: parseFloat(promoPrice.washerRate) })
                if (promoPrice.washerRate === 0) {
                  setPromotion(false)
                }
              } else if (promoPrice.discountedPrice || promoPrice.discountedPrice === 0) {
                // flat promotion
                setDiscountAmount({ type: "Flat", amount: (parseFloat(promoPrice.price) - parseFloat(promoPrice.discountedPrice)) })
                if ((parseFloat(promoPrice.price) - parseFloat(promoPrice.discountedPrice)) === 0) {
                  setPromotion(false)
                }
              }
            }
          }
        } else {
          // more than 2 mode with the same price
          const newModeIndex = modeSelection.map(data => data.price).lastIndexOf(newAmount)
          setAmount(modeSelection[newModeIndex].price)
          setMode(modeSelection[newModeIndex].name)
          setPromotion(false)
          if ((user && machine.promo.status) || (!user && machine.non_member_promotion && machine.promo.status)) {
            // promotion
            const promoPrice = machine.promo.priceData.filter(data => data.mode === modeSelection[newModeIndex].name)[0]
            if (promoPrice) {
              setPromotion(true)
              setAmount(parseFloat(promoPrice.price))
              setMode(promoPrice.mode)
              if (promoPrice.washerRate || promoPrice.washerRate === 0) {
                // rate promotion
                setDiscountAmount({ type: "Rate", amount: parseFloat(promoPrice.washerRate) })
                if (promoPrice.washerRate === 0) {
                  setPromotion(false)
                }
              } else if (promoPrice.discountedPrice || promoPrice.discountedPrice === 0) {
                // flat promotion
                setDiscountAmount({ type: "Flat", amount: (parseFloat(promoPrice.price) - parseFloat(promoPrice.discountedPrice)) })
                if ((parseFloat(promoPrice.price) - parseFloat(promoPrice.discountedPrice)) === 0) {
                  setPromotion(false)
                }
              }
            }
          }
        }
      }
    }
  };

  const addAmount = () => {
    const currentModeIndex = modeSelection.findIndex(data => data.name === mode)
    if (machine.isWashup) {
      // no step, fix to mode price
      if (currentModeIndex < (modeSelection.length - 1)) {
        const newMode = modeSelection[currentModeIndex + 1].name
        changeMode(newMode)
      }
    } else {
      const step = parseFloat(machine.step || 1)
      if (currentModeIndex < (modeSelection.length - 1)) {
        const newMode = modeSelection[currentModeIndex + 1].name
        const newModePrice = machine.priceData.filter(data => data.name === newMode)[0]
        if (newModePrice.price === amount) { // if 2 mode with same price
          changeMode(newMode)
          return;
        }
      }

      const newAmount = parseFloat(amount) + step
      if (newAmount <= modeSelection[modeSelection.length - 1].price) {
        const newPrice = machine.priceData.filter(data => data.price === newAmount)
        if (newPrice.length <= 0) {
          // price not found in priceData, mode: OTHER
          setAmount(newAmount)
          setMode("OTHER")
          setPromotion(false)
          if (machine.promo.status) {
            setDiscountAmount(null)
          }
        } else if (newPrice.length === 1) {
          // only 1 mode with that price
          setAmount(newPrice[0].price)
          setMode(newPrice[0].name)
          setPromotion(false)
          if ((user && machine.promo.status) || (!user && machine.non_member_promotion && machine.promo.status)) {
            // promotion
            const promoPrice = machine.promo.priceData.filter(data => data.mode === newPrice[0].name)[0]
            if (promoPrice) {
              setPromotion(true)
              setAmount(parseFloat(promoPrice.price))
              setMode(promoPrice.mode)
              if (promoPrice.washerRate || promoPrice.washerRate === 0) {
                // rate promotion
                setDiscountAmount({ type: "Rate", amount: parseFloat(promoPrice.washerRate) })
                if (promoPrice.washerRate === 0) {
                  setPromotion(false)
                }
              } else if (promoPrice.discountedPrice || promoPrice.discountedPrice === 0) {
                // flat promotion
                setDiscountAmount({ type: "Flat", amount: (parseFloat(promoPrice.price) - parseFloat(promoPrice.discountedPrice)) })
                if ((parseFloat(promoPrice.price) - parseFloat(promoPrice.discountedPrice)) === 0) {
                  setPromotion(false)
                }
              }
            }
          }
        } else {
          // more than 2 mode with the same price
          const newModeIndex = modeSelection.map(data => data.price).indexOf(newAmount)
          setAmount(modeSelection[newModeIndex].price)
          setMode(modeSelection[newModeIndex].name)
          setPromotion(false)
          if ((user && machine.promo.status) || (!user && machine.non_member_promotion && machine.promo.status)) {
            // promotion
            const promoPrice = machine.promo.priceData.filter(data => data.mode === modeSelection[newModeIndex].name)[0]
            if (promoPrice) {
              setPromotion(true)
              setAmount(parseFloat(promoPrice.price))
              setMode(promoPrice.mode)
              if (promoPrice.washerRate || promoPrice.washerRate === 0) {
                // rate promotion
                setDiscountAmount({ type: "Rate", amount: parseFloat(promoPrice.washerRate) })
                if (promoPrice.washerRate === 0) {
                  setPromotion(false)
                }
              } else if (promoPrice.discountedPrice || promoPrice.discountedPrice === 0) {
                // flat promotion
                setDiscountAmount({ type: "Flat", amount: (parseFloat(promoPrice.price) - parseFloat(promoPrice.discountedPrice)) })
                if ((parseFloat(promoPrice.price) - parseFloat(promoPrice.discountedPrice)) === 0) {
                  setPromotion(false)
                }
              }
            }
          }
        }
      }
    }
  };

  const renderVouchers = () => {
    if (user && !machine.promo.status) {
      if (user.vouchers && user.vouchers.length) {
        return user.vouchers
          .filter((voucher) => voucher.available && voucher.washer_use)
          .map((voucher) => {
            let currentVoucherAmount = voucher.amount
            return (
              <div key={voucher.id} className="payment-voucher">
                {STORETYPE === "cleanpro" ? (
                  <div
                    style={{ borderRadius: "0" }}
                    className="voucher my-3"
                  >
                    <div
                      className="voucher-content center-content"
                      style={{
                        color: "black",
                        position: "relative",
                        zIndex: "99",
                        marginTop: "1em",
                      }}
                    >
                      <div
                        className="d-flex"
                        style={{
                          width: "50%",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignContent: "center",
                          marginLeft: "1em",
                          textAlign: "left",
                        }}
                      >
                        <h2 className="font-weight-bold">
                          {voucher.type === "Flat" ? <Trans i18nKey="voucherCreditsOff" count={currentVoucherAmount}>{{ currentVoucherAmount }} Credits OFF</Trans> : <Trans i18nKey="voucherPercentageOff" count={currentVoucherAmount}>{{ currentVoucherAmount }}% OFF</Trans>}
                        </h2>

                        <small>{voucher.description}</small>

                        {voucher.expired_date ? (
                          <h6 style={{ color: "grey" }} className="m-0 p-0">
                            <small>{t("Valid Till")}: {new Date(voucher.expired_date).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })}</small>
                          </h6>
                        ) : null}

                      </div>
                      <div
                        style={{ width: "30%" }}
                        className="center-content"
                      >
                        <input
                          onChange={() => setId(voucher.id)}
                          style={{
                            border: "1px solid grey",
                            cursor: "pointer",
                            opacity: "1",
                            pointerEvents: "fill",
                            position: "relative",
                          }}
                          type="radio"
                          name="paymentVoucher"
                          id={voucher.id}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    onClick={(e) => chooseVoucher(voucher.type, voucher.amount, voucher.id)}
                    className="list-group-item"
                  >
                    <p>{voucher.title}</p>
                    <h6>
                      {t("Discount")} :- {voucher.type === "Flat" ? currency(machine.country) : null}
                      {voucher.amount}
                      {voucher.type === "Rate" ? "%" : null}
                    </h6>
                  </div>
                )}
              </div>
            );
          });
      } else {
        return (
          <div className="mt-4">
            <p style={{ color: "red" }}>{t("No Vouchers Available")}</p>
          </div>
        );
      }
    } else {
      return null;
    }
  };

  const handleSubmit = () => {
    let voucherReminder = false;
    let laundryPassBlocking = false;
    let laundryPassError = ""

    if (user && !machine.promo.status) {
      if (user.vouchers && user.vouchers.length) {
        let userVoucher = user.vouchers.filter((voucher) => voucher.available && voucher.washer_use);
        if (userVoucher.length !== 0 && !discountAmount && !laundryPass) {
          voucherReminder = true;
        }
      }
    }

    if (laundryPass) {
      let modeRules = laundryPassRule.filter(rule => rule.type === "Mode")
      let weightRules = laundryPassRule.filter(rule => rule.type === "Weight")
      let outletRules = laundryPassRule.filter(rule => rule.type === "Outlet")

      if (modeRules.length > 0) {
        let modeValues = modeRules.map(rule => (rule.value).toLowerCase().replace(/ /g, ''))
        if (modeRules[0].applicable) {
          // Only can use in these mode
          if (!modeValues.includes((mode).toLowerCase().replace(/ /g, ''))) {
            laundryPassBlocking = true
            laundryPassError = `Laundry Pass only applicable in ${modeValues.join(", ")} temperature`
          }
        } else {
          // cannot use in these mode
          if (modeValues.includes((mode).toLowerCase().replace(/ /g, ''))) {
            laundryPassBlocking = true
            laundryPassError = `Laundry Pass not applicable in ${modeValues.join(", ")} temperature`
          }
        }
      }

      if (weightRules.length > 0) {
        let weightValues = weightRules.map(rule => parseInt(rule.value))
        if (weightRules[0].applicable) {
          // Only can use in these weight
          if (!weightValues.includes(parseInt(machine.capacity))) {
            laundryPassBlocking = true
            laundryPassError = `Laundry Pass only applicable in capacity ${weightValues.join("kg, ")}kg`
          }
        } else {
          // cannot use in these weight
          if (weightValues.includes(parseInt(machine.capacity))) {
            laundryPassBlocking = true
            laundryPassError = `Laundry Pass not applicable in capacity ${weightValues.join("kg, ")}kg`
          }
        }
      }

      if (outletRules.length > 0) {
        let outletValues = outletRules.map(rule => rule.outletId)
        if (outletRules[0].applicable) {
          // Only can use in these outlet
          if (!outletValues.includes(machine.outletId)) {
            laundryPassBlocking = true
            laundryPassError = "Laundry Pass not applicable in this outlet"
          }
        } else {
          // cannot use in these outlet
          if (outletValues.includes(machine.outletId)) {
            laundryPassBlocking = true
            laundryPassError = "Laundry Pass not applicable in this outlet"
          }
        }
      }
    }

    const emailRegex = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/
    const numberRegex = /^\d+$/;

    if (notificationType === "Email" && !email) {
      mySwal.fire("", "Please fill in your email address", "info")
    } else if (notificationType === "Email" && !emailRegex.test(email)) {
      mySwal.fire(t("Error"), t("Invalid Email Address"), "error");
    } else if (notificationType === "SMS" && !number) {
      mySwal.fire("", t("Please fill in your phone number"), "info")
    } else if (notificationType === "SMS" && machine.country === "Taiwan" && (!numberRegex.test(number) || number.length !== 10 || number.substring(0, 2) !== "09")) {
      mySwal.fire(t("Error"), t("Invalid Phone Number"), "error");
    } else if (notificationType === "SMS" && machine.country === "Malaysia" && (!numberRegex.test(number) || number.length <= 9 || number.length >= 12 || number.substring(0, 2) !== "01")) {
      mySwal.fire(t("Error"), t("Invalid Phone Number"), "error");
    } else if (laundryPassBlocking) {
      mySwal.fire(t("Error"), t(laundryPassError), "error");
    } else {
      if (machine.online && machine.outletStatus === "online" && outlet) {
        if (voucherReminder) {
          setVoucherReminderModal(true)
        } else {
          setPaymentMethodModal(true)
        }
      } else {
        mySwal.fire(t("Error"), t("This machine is currently offline, please try again later."), "error")
      }
    }
  };

  const handlePaymentMethod = (method) => {
    if (method === "eToken" && (parseFloat(user.token) < parseFloat(getPaymentAmount("totalPaymentAmount")))) {
      setInsufficientTokenModal(true)
      setPaymentMethodModal(false)
    } else {
      setPaymentMethod(method)
      if (method === "eToken") {
        setPaymentConfirmationText(<p className="text-center">{t("You have")} <strong>{user.token}</strong> {STORETYPE === "cleanpro" ? t("credits") : t("e-Tokens")}.<br />{t("Pay")} <strong>{getPaymentAmount("totalPaymentAmount")}</strong> {STORETYPE === "cleanpro" ? t("credits") : t("e-Tokens")} {("to")} <strong>{machine.name}</strong>?</p>)
      } else if (method === "duitnow") {
        setPaymentConfirmationText(<p className="text-center"><Trans i18nKey="The Duitnow QR code will be downloaded and you need to open your banking app to make the payment. Pay {{ currency }}{{ totalPaymentAmount }} to {{ machineName }}?">The Duitnow QR code will be downloaded and you need to open your banking app to make the payment. Pay {{ currency: currency(machine.country) }}{{ totalPaymentAmount: getPaymentAmount("totalPaymentAmount") }} to {{ machineName: machine.name }}?</Trans></p>)
      } else {
        if (voucherId && discountAmount && discountAmount.type === "Flat" && (discountAmount.amount > amount)) {
          setPaymentConfirmationText(<p className="text-center">{t("You may under utilized the voucher.")} {t("Proceed to pay")} {currency(machine.country)} {getPaymentAmount("totalPaymentAmount")} {t("to")} {machine.name}?</p>)
        } else {
          setPaymentConfirmationText(<p className="text-center">{t("Pay")} {currency(machine.country)} {getPaymentAmount("totalPaymentAmount")} {t("to")} {machine.name}?</p>)
        }
      }
      setPaymentMethodModal(false)
      setPaymentConfirmationModal(true)
    }
  }

  const confirmPayment = () => {
    let sendThis = {
      amount,
      machine,
      paymentAmount: getPaymentAmount("paymentAmount"),
      outlet,
      duration: renderDuration(),
      notificationType,
      email,
      sms_amount: getPaymentAmount("sms"),
      number,
      mode,
    };

    let endPoint = "/api/nonuser/payment";

    let headers = {
      "Content-Type": "application/json"
    }

    if (user) {
      sendThis.voucherId = voucherId;
      sendThis.memberId = user.memberId;
      sendThis.discountCode = discountCode;
      sendThis.point_amount = getPaymentAmount("pointAmount");
      sendThis.point_currency = user.point_currency;
      sendThis.laundryPass = laundryPass;
      endPoint = "/api/user/payment";
      headers = {
        "Content-Type": "application/json",
        "auth-token": token
      }
    }

    if (paymentMethod === "eToken") {
      sendThis.token = true
    } else if (paymentMethod === "duitnow") {
      if (machine.duitnow) {
        sendThis.duitnow = true
      } else if (machine.rhbduitnow) {
        sendThis.rhbduitnow = true
      }
    } else if (paymentMethod === "paywave") {
      sendThis.terminal = true
    }

    if (paymentMethod === "eWallet" && machine.outletType === "pagbank" && parseFloat(getPaymentAmount("totalPaymentAmount")) > 0) {
      let data = {
        country: machine.country,
        description: machine.name,
        paymentAmount: getPaymentAmount("totalPaymentAmount"),
        user,
        endPoint,
        sendThis,
      }
      history.push("/payment/pagbank", data)
    } else {
      setLoading(true)
      axios
        .post(endPoint, sendThis, { headers })
        .then(res => {
          saveTransactionId(res.data.transactionId)
          saveTransactionType("Transaction")
          setPaymentConfirmationModal(false)
          setLoading(false)
          if (res.data.status === "rm") {
            window.location.href = res.data.data;
          } else if (res.data.status === "laundro") {
            history.push("/thankyou");
          } else if (res.data.status === "razer") {
            setRazerBody(res.data.data)
          } else if (res.data.status === "duitnow") {
            history.push("/guide/duitnow", { url: res.data.data, transactionId: res.data.transactionId })
          } else if (res.data.status === "ghl") {
            setGhlBody(res.data.data)
          } else if (res.data.status === "terminal") {
            history.push("/guide/terminal", { transactionId: res.data.transactionId })
          } else if (res.data.status === "rhbduitnow") {
            history.push("/guide/duitnow", { image: `data:image/png;base64,${res.data.data.data}`, transactionId: res.data.transactionId })
          }
        })
        .catch(err => {
          setLoading(false)
          mySwal.fire(t("Error"), t(err.response.data.error), "error")
        })
    }
  }

  // RENDER
  return (
    <div id="washer-payment" className="mt-4">
      {machine.promo.status && promotion ? (
        <div className={STORETYPE === "cleanpro" ? "text-center mb-5 pt-2" : "text-center mb-5"}>
          <img
            src={promotionBanner}
            alt="promotion banner"
            className="img-fluid m-auto"
            style={{ height: "90px", width: "auto" }}
          />
        </div>
      ) : null}
      {STORETYPE !== "cleanpro" && STORETYPE !== "washup" && machine.type !== "Detergent Vending" && (
        <div className="payment-info">
          <div className="payment-info-box">
            <div className="payment-info-image">
              <img src={washer1} alt="washer1" className="img-fluid" />
            </div>
            <p className="text-secondary font-weight-bold">{t("MACHINE")}</p>
            <p className="font-weight-bold">{machine.name}</p>
          </div>
          <div className="payment-info-box">
            <div className="payment-info-image">
              <img src={washer2} alt="washer2" className="img-fluid" />
            </div>
            <p className="text-secondary font-weight-bold">{t("TYPE")}</p>
            <p className="font-weight-bold">{t(machine.type)}</p>
          </div>
          <div className="payment-info-box">
            <div className="payment-info-image">
              <img src={washer3} alt="washer3" className="img-fluid" />
            </div>
            <p className="text-secondary font-weight-bold">{t("CAPACITY")}</p>
            <p className="font-weight-bold">{machine.capacity}</p>
          </div>
          <div className="payment-info-box">
            <div className="payment-info-image">
              <img src={washer4} alt="washer4" className="img-fluid" />
            </div>
            <p className="text-secondary font-weight-bold">{t("DURATION")}</p>
            <p className="font-weight-bold">
              {renderDuration()} {t("MIN")}
            </p>
          </div>
        </div>
      )}

      {STORETYPE === "cleanpro" ? (
        <h6 style={{ margin: "20px 0 5px 0", padding: "0px 5vw" }}>
          <img src={temp} alt="" style={{ width: "10px" }} />
          <span style={{ marginLeft: "10px", fontWeight: "600", color: "#333333" }}>
            {t("Select temperature")}
          </span>
        </h6>
      ) : STORETYPE === "washup" ? (
        <h6 style={{ margin: "20px 0 5px 0" }}>
          <img src={washupTemperature} alt="" />
          <strong className="ms-2">{t("Select Water Temperature")}</strong>
        </h6>
      ) : machine.type === "Detergent Vending" ? (
        <h6 style={{ margin: "20px 0 5px 0" }}>
          <strong className="ml-1">{t("Select Product / Key in Amount")}</strong>
        </h6>
      ) : (
        <h6 style={{ margin: "20px 0 5px 0" }}>
          <strong className="ml-1">{t("Select Water Temperature")}</strong>
        </h6>
      )}

      <div className={STORETYPE === "washup" ? "dng-selection-buttons" : "mt-3 selection-buttons"}>
        {
          modeSelection.map((m, index) => {
            return (
              <button
                key={index}
                id="washer-mode"
                className={buttonStyle(m.name)}
                onClick={(e) => changeMode(m.name)}
              >
                {m.name}
              </button>
            )
          })
        }
      </div>

      {STORETYPE === "cleanpro" && (
        <div style={{ border: "5px solid #f2f2f2", marginTop: "20px" }}></div>
      )}

      {
        STORETYPE === "cleanpro" && machine.notification && !machine.running ? (
          <div>
            <div style={{ padding: "0px 5vw" }} className="payment-notification-container py-2">
              <p><b>{t("Select how you want to receive a notification when your laundry is finished")}:</b></p>
              <div className="d-flex justify-content-around form-group pt-1">
                <label htmlFor="radio2">
                  <input type="radio" name="notificationType" id="radio2" value="Email" className="with-gap" checked={notificationType === "Email"} onChange={e => setNotificationType(e.target.value)} />
                  <span style={{ color: "black" }}>{t("Email")}</span>
                </label>

                <label htmlFor="radio3">
                  <input type="radio" name="notificationType" id="radio3" value="SMS" className="with-gap" checked={notificationType === "SMS"} onChange={e => setNotificationType(e.target.value)} />
                  <span style={{ color: "black" }}>SMS ({getPaymentAmount("sms")} {t("credit")}/SMS)</span>
                </label>
              </div>

              {
                notificationType === "Email" ? (
                  <div className="form-group">
                    <input type="email" id="email" className="form-control browser-default" value={email} onChange={e => setEmail(e.target.value)} required placeholder={t("Email Address")} />
                  </div>
                ) : null
              }

              {
                notificationType === "SMS" ? (
                  <div className="form-group">
                    <input type="tel" id="number" className="form-control browser-default" value={number} onChange={e => setNumber(e.target.value)} required placeholder={t("Phone Number")} />
                  </div>
                ) : null
              }
            </div>
            <div style={{ border: "5px solid #f2f2f2" }}></div>
          </div>
        ) : null
      }

      {STORETYPE !== "cleanpro" && (
        <div className="payment-amount">
          <span id="minus-amount" onClick={(e) => minusAmount()}>
            <i className="bi bi-dash-circle-fill"></i>
          </span>

          <div>
            <h6 className="m-0 p-0 font-weight-bold text-secondary">
              {t("AMOUNT TO PAY")}
            </h6>
            <h3 className="m-0 p-0 font-weight-bold">
              {currency(machine.country)} {/\./g.test(amount.toString()) ? parseFloat(amount).toFixed(2) : amount}
            </h3>
          </div>

          <span id="add-amount" onClick={(e) => addAmount()}>
            <i className="bi bi-plus-circle-fill"></i>
          </span>
        </div>
      )}

      {STORETYPE === "cleanpro" ? (
        <div className="pt-3" style={{ padding: "0px 5vw" }}>
          {
            user && !machine.promo.status ? (
              <div>
                {
                  (discountCode || !voucherId) && !laundryPass ? (
                    <div
                      id="cleanpro-input-discountcode"
                      onClick={(e) => setDiscountCodeModal(true)}
                      className="checkout-payment py-2"
                    >
                      <i className="bi bi-percent text-center" style={{ width: "32px" }}></i>
                      <p className="ml-1">
                        {discountCode ? discountCode : t("Discount Code")}
                      </p>
                      <p>
                        <i className="arrow right"></i>
                      </p>
                    </div>
                  ) : null
                }

                {
                  (voucherId || !discountCode) && !laundryPass ? (
                    <div
                      id="cleanpro-select-voucher"
                      onClick={(e) => setOpen(true)}
                      className="checkout-payment py-2"
                    >
                      <img
                        className="fit-contain"
                        style={{ width: "32px" }}
                        src={ticket}
                        alt=""
                      />
                      <p className={`ml-1 ${voucherId && "font-weight-bold"}`}>
                        {voucherId ? t("1 voucher selected") : t("No voucher selected")}
                      </p>
                      <p>
                        <i className="arrow right"></i>
                      </p>
                    </div>
                  ) : null
                }
              </div>
            ) : null
          }
          {
            user && laundryPassOption && !voucherId && !discountCode ? (
              <div id="cleanpro-point" className="d-flex justify-content-between py-2">
                <div className="d-flex align-items-center">
                  <img style={{ width: "32px" }} src={voucherIcon} alt="" />
                  <p className="ml-1">
                    {t("Laundry Pass")}
                  </p>
                </div>
                <div className="toggle-switch">
                  <input type="checkbox" className="toggle-switch-checkbox" name="laundrypass" id="laundrypass" checked={laundryPass} onChange={() => setLaundryPass(!laundryPass)} />
                  <label className="toggle-switch-label" htmlFor="laundrypass">
                    <span className="toggle-switch-inner" />
                    <span className="toggle-switch-switch" />
                  </label>
                </div>
              </div>
            ) : null
          }
          {
            user && user.loyalty_point ? (
              <div id="cleanpro-point" className="d-flex justify-content-between py-2">
                <div className="d-flex align-items-center">
                  <img style={{ width: "32px" }} src={cleanproCreditIcon} alt="" />
                  <p className="ml-1">
                    {user.point} {user.loyalty_point_name}
                  </p>
                </div>
                <div className="toggle-switch">
                  <input type="checkbox" className="toggle-switch-checkbox" name="point" id="point" checked={point} onChange={e => setPoint(!point)} />
                  <label className="toggle-switch-label" htmlFor="point">
                    <span className="toggle-switch-inner" />
                    <span className="toggle-switch-switch" />
                  </label>
                </div>
              </div>
            ) : null
          }
          {
            user && user.tokenSetting ? (
              <div
                id="cleanpro-reload-token"
                onClick={() => history.push("/user/reload")}
                className="checkout-payment py-2"
              >
                <img style={{ width: "32px" }} src={creditBear} alt="" />
                <p className="ml-1">
                  {user.token} {t("credits")}
                </p>
                <p>
                  <i className="arrow right"></i>
                </p>
              </div>
            ) : null
          }
          {
            user ? <div style={{ border: "1px solid #f2f2f2", margin: "5px -20px" }}></div> : null
          }
        </div>
      ) : (
        <div className="list-group payment-list-group">
          <div className="list-group-item payment-list-group-item">
            <div>
              <p className="text-secondary">{t("PRICE")}</p>
            </div>

            <div>
              <p>
                {currency(machine.country)} {/\./g.test(amount.toString()) ? amount.toFixed(2) : amount}
              </p>
            </div>
          </div>

          {user && !machine.promo.status ? (
            <div>
              {(discountCode || !voucherId) && !laundryPass ? (
                <div className="list-group-item payment-list-group-item align-items-center">
                  <div>
                    <p className="text-secondary">{t("DISCOUNT CODE")}</p>
                  </div>

                  <div>
                    <button
                      id="select-voucher"
                      type="button"
                      onClick={(e) => setDiscountCodeModal(true)}
                      className="text-secondary"
                      style={{
                        border: "none",
                        backgroundColor: "white",
                        outline: "none",
                        fontWeight: "bold",
                        paddingRight: "0px",
                        fontSize: "large",
                      }}
                    >
                      {discountCode && discountAmount
                        ? `${discountCode} (${discountText()})`
                        : t("Enter code >")}
                    </button>
                  </div>
                </div>
              ) : null}

              {(voucherId || !discountCode) && !laundryPass ? (
                <div className="list-group-item payment-list-group-item align-items-center">
                  <div>
                    <p className="text-secondary">{t("VOUCHER")}</p>
                  </div>

                  <div>
                    <button
                      id="select-voucher"
                      type="button"
                      onClick={(e) => setOpen(true)}
                      className="text-secondary"
                      style={{
                        border: "none",
                        backgroundColor: "white",
                        outline: "none",
                        fontWeight: "bold",
                        paddingRight: "0px",
                        fontSize: "large",
                      }}
                    >
                      {voucherId ? discountText() : t("Select Voucher >")}
                    </button>
                  </div>
                </div>
              ) : null}
            </div>
          ) : machine.promo.status && promotion ? (
            <div className="list-group-item payment-list-group-item">
              <div>
                <p className="text-secondary">{t("DISCOUNT")}</p>
              </div>

              <div>
                <p>{discountText()}</p>
              </div>
            </div>
          ) : null}

          {
            user && laundryPassOption && !voucherId && !discountCode ? (
              <div className="list-group-item payment-list-group-item align-items-center">
                <div>
                  <p className="text-secondary">{t("Laundry Pass")}</p>
                </div>

                <div className="toggle-switch">
                  <input type="checkbox" className="toggle-switch-checkbox" name="laundrypass" id="laundrypass" checked={laundryPass} onChange={e => setLaundryPass(!laundryPass)} />
                  <label className="toggle-switch-label" htmlFor="laundrypass">
                    <span className="toggle-switch-inner" />
                    <span className="toggle-switch-switch" />
                  </label>
                </div>
              </div>
            ) : null
          }

          {
            user && user.loyalty_point ? (
              <div className="list-group-item payment-list-group-item align-items-center">
                <div>
                  <p className="text-secondary">{user.loyalty_point_name} ({user.point})</p>
                </div>

                <div className="toggle-switch">
                  <input type="checkbox" className="toggle-switch-checkbox" name="point" id="point" checked={point} onChange={e => setPoint(!point)} />
                  <label className="toggle-switch-label" htmlFor="point">
                    <span className="toggle-switch-inner" />
                    <span className="toggle-switch-switch" />
                  </label>
                </div>
              </div>
            ) : null
          }

          {
            point ? (
              <div className="list-group-item payment-list-group-item">
                <div>
                  <p className="text-secondary">{user.loyalty_point_name}</p>
                </div>

                <div>
                  <p className="text-secondary">{getPaymentAmount("pointAmount")} {user.loyalty_point_name} ({currency(machine.country)} {getPaymentAmount("pointValue")})</p>
                </div>
              </div>
            ) : null
          }

          {
            notificationType === "SMS" ? (
              <div className="list-group-item payment-list-group-item">
                <div>
                  <p className="text-secondary">SMS</p>
                </div>

                <div>
                  <p>{currency(machine.country)} {getPaymentAmount("sms")}</p>
                </div>
              </div>
            ) : null
          }

          <div className="list-group-item payment-list-group-item">
            <div>
              <p
                style={{ fontSize: "24px", fontWeight: "bolder" }}
                className="text-secondary"
              >
                {t("TOTAL")}
              </p>
            </div>
            <div>
              <p style={{ fontSize: "24px", fontWeight: "bolder" }}>
                {currency(machine.country)} {getPaymentAmount("totalPaymentAmount")}
              </p>
            </div>
          </div>
        </div>
      )}

      {STORETYPE === "cleanpro" && discountAmount && (
        <div className="py-2 d-flex justify-content-between" style={{ padding: "0px 5vw" }}>
          <h6>{t("Offers")}</h6>
          <h6 style={{ color: "#333333" }}>-{discountText()}</h6>
        </div>
      )}

      {STORETYPE === "cleanpro" && point && (
        <div className="py-2 d-flex justify-content-between" style={{ padding: "0px 5vw" }}>
          <h6>{user.loyalty_point_name}</h6>
          <h6 style={{ color: "#333333" }}>-{getPaymentAmount("pointAmount")} {user.loyalty_point_name} ({!user.tokenSetting ? currency(machine.country) : ""} {getPaymentAmount("pointValue")} {user.tokenSetting ? t("credit") : ""})</h6>
        </div>
      )}

      {STORETYPE === "cleanpro" && (
        <div className="py-2 d-flex justify-content-between" style={{ padding: "0px 5vw" }}>
          <h6>{t("Amount to Pay")}</h6>
          <h6 className="font-weight-bold blue-t">
            {user && user.tokenSetting ? "" : currency(machine.country)} {getPaymentAmount("totalPaymentAmount")} {user && user.tokenSetting ? t("credits") : ""}
          </h6>
        </div>
      )}

      {
        STORETYPE !== "cleanpro" && machine.notification && !machine.running ? (
          <div className="px-3 pt-3">
            <h6><strong>{t("Notification")}</strong></h6>
            <p>{t("Do you want to receive notification when wash cycle done?")}</p>

            <div className="d-flex justify-content-around form-group">
              <label htmlFor="radio1">
                <input type="radio" name="notificationType" id="radio1" value="" className="with-gap" checked={notificationType === ""} onChange={e => setNotificationType(e.target.value)} />
                <span style={{ color: "black" }}>{t("No")}</span>
              </label>

              <label htmlFor="radio2">
                <input type="radio" name="notificationType" id="radio2" value="Email" className="with-gap" checked={notificationType === "Email"} onChange={e => setNotificationType(e.target.value)} />
                <span style={{ color: "black" }}>{t("Email")}</span>
              </label>

              <label htmlFor="radio3">
                <input type="radio" name="notificationType" id="radio3" value="SMS" className="with-gap" checked={notificationType === "SMS"} onChange={e => setNotificationType(e.target.value)} />
                <span style={{ color: "black" }}>SMS ({t("pay extra")} {currency(machine.country)}{getPaymentAmount("sms")})</span>
              </label>
            </div>

            {
              notificationType === "Email" ? (
                <div className="form-group">
                  <label htmlFor="email">{t("Email Address")}:</label>
                  <input type="email" id="email" className="form-control browser-default" value={email} onChange={e => setEmail(e.target.value)} required />
                </div>
              ) : null
            }

            {
              notificationType === "SMS" ? (
                <div className="form-group">
                  <label htmlFor="number">{t("Phone Number")}:</label>
                  <input type="tel" id="number" className="form-control browser-default" value={number} onChange={e => setNumber(e.target.value)} required />
                </div>
              ) : null
            }

          </div>
        ) : null
      }

      <div id="payment-button-container" className={STORETYPE === "cleanpro" ? "px-3 pt-3" : "mt-5 px-3"}>
        {STORETYPE === "cleanpro" && (
          <p style={{ fontSize: "12px" }} className="mb-2 text-center">
            *{t("Please ensure that the machine door is shut & your laundry is inside.")}
          </p>
        )}
        <button
          id="pay"
          onClick={(e) => handleSubmit(e)}
          className="btn form-control big-button"
        >
          {STORETYPE === "cleanpro" ? t("Pay now") : t("PAY")}
        </button>
      </div>

      {/* CUSTOM VOUCHER MODAL */}
      {user && !machine.promo.status ? (
        <div>
          <div
            onClick={(e) => setOpen(false)}
            className={isOpen ? "custom-overlay isOpen" : "custom-overlay"}
          ></div>
          <div className={isOpen ? "custom-modal isOpen" : "custom-modal"}>
            <div className="custom-modal-header">
              {voucherId ? (
                <button
                  id="remove-voucher"
                  onClick={(e) => removeVoucher()}
                  type="button"
                  className="custom-modal-title m-0 p-0"
                  style={{
                    color: "red",
                    border: "none",
                    backgroundColor: "white",
                    margin: 0,
                    padding: 0,
                  }}
                >
                  <i className="bi bi-slash-circle"></i> {t("Remove Voucher")}
                </button>
              ) : (
                <h6 className="custom-modal-title">
                  {STORETYPE === "cleanpro" ? (
                    t("Select voucher to use")
                  ) : (
                    <>
                      <i className="bi bi-tags-fill"></i> {t("Select Your Voucher")}
                    </>
                  )}
                </h6>
              )}
            </div>

            <div className="custom-modal-body">
              <ul className="list-group list-group-flush px-4">
                {renderVouchers()}
              </ul>
            </div>
            {
              STORETYPE === "cleanpro" ? (
                <button onClick={useVoucher} className="btn big-button" style={{ width: "90%" }}>
                  {t("Use voucher")}
                </button>
              ) : null
            }
          </div>
        </div>
      ) : null}

      {
        razerBody ? (
          <div>
            <form action={razerBody.paymentDomain} method="post">
              <input type="hidden" name="amount" value={razerBody.amount} />
              <input type="hidden" name="merchant_id" value={razerBody.merchant_id} />
              <input type="hidden" name="orderid" value={razerBody.orderid} />
              {razerBody.bill_name ? <input type="hidden" name="bill_name" value={razerBody.bill_name} /> : null}
              {razerBody.bill_email ? <input type="hidden" name="bill_email" value={razerBody.bill_email} /> : null}
              {razerBody.bill_mobile ? <input type="hidden" name="bill_mobile" value={razerBody.bill_mobile} /> : null}
              <input type="hidden" name="bill_desc" value={razerBody.bill_desc} />
              <input type="hidden" name="country" value={razerBody.country} />
              <input type="hidden" name="vcode" value={razerBody.vcode} />
              <input type="hidden" name="currency" value={razerBody.currency} />
              <input type="hidden" name="returnurl" value={razerBody.returnurl} />
              <input type="hidden" name="callbackurl" value={razerBody.callbackurl} />
              <input type="hidden" name="cancelurl" value={razerBody.cancelurl} />

              <input type="submit" name="submit" value="Pay with razer" hidden ref={razerButtonClick} />
            </form>
          </div>
        ) : null
      }

      {
        ghlBody ? (
          <div>
            <form name="frmPayment" method="post" action={ghlBody.frmPayment}>
              <input type="hidden" name="TransactionType" value={ghlBody.TransactionType} />
              <input type="hidden" name="PymtMethod" value={ghlBody.PymtMethod} />
              <input type="hidden" name="ServiceID" value={ghlBody.ServiceID} />
              <input type="hidden" name="PaymentID" value={ghlBody.PaymentID} />
              <input type="hidden" name="OrderNumber" value={ghlBody.OrderNumber} />
              <input type="hidden" name="PaymentDesc" value={ghlBody.PaymentDesc} />
              <input type="hidden" name="MerchantReturnURL" value={ghlBody.MerchantReturnURL} />
              <input type="hidden" name="Amount" value={ghlBody.Amount} />
              <input type="hidden" name="CurrencyCode" value={ghlBody.CurrencyCode} />
              <input type="hidden" name="CustIP" value={ghlBody.CustIP} />
              <input type="hidden" name="CustName" value={ghlBody.CustName} />
              <input type="hidden" name="CustEmail" value={ghlBody.CustEmail} />
              <input type="hidden" name="CustPhone" value={ghlBody.CustPhone} />
              <input type="hidden" name="HashValue" value={ghlBody.HashValue} />
              <input type="hidden" name="PageTimeout" value={ghlBody.PageTimeout} />

              <input type="submit" name="submit" value="Pay with GHL" hidden ref={ghlButtonClick} />
            </form>

          </div>
        ) : null
      }
      <Modal
        open={discountCodeModal}
        onCancel={(e) => setDiscountCodeModal(false)}
        footer={null}
        closable={true}
        centered
        title={t("Enter Discount Code")}
      >
        <div>
          <form onSubmit={checkDiscountCode}>
            {discountCode ? (
              <div className="form-group text-left">
                <label htmlFor="code">{t("Discount Code")}</label>
                <input
                  type="text"
                  id="code"
                  name="code"
                  value={discountCode}
                  className="form-control browser-default"
                  readOnly
                />
              </div>
            ) : (
              <div className="form-group text-left">
                <label htmlFor="code">{t("Discount Code")}</label>
                <input
                  type="text"
                  id="code"
                  name="code"
                  value={discountCodeInput}
                  className="form-control browser-default"
                  onChange={(e) =>
                    setDiscountCodeInput(e.target.value.toUpperCase())
                  }
                  required
                />
              </div>
            )}

            <div className="text-center pt-3">
              {loading ? (
                <div className="text-center">
                  <div
                    className={`spinner-border text-${STORETYPE === "cuci" ? "warning" : "primary"}`}
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : discountCode ? (
                <input
                  id="remove-discount-code"
                  type="button"
                  value={t("Remove Code")}
                  onClick={() => removeVoucher()}
                  className="btn btn-primary mx-2"
                />
              ) : (
                <input
                  id="submit-discount-code"
                  type="submit"
                  value={t("Submit")}
                  className="btn btn-primary mx-2"
                />
              )}
              <button
                id="close-discount-code"
                type="button"
                className="btn btn-secondary"
                onClick={(e) => setDiscountCodeModal(false)}
              >
                {t("Close")}
              </button>
            </div>
          </form>
        </div>
      </Modal>

      <Modal
        open={insufficientTokenModal}
        onCancel={(e) => setInsufficientTokenModal(false)}
        footer={null}
        closable={false}
        centered
      >
        <div className={theme}>
          {
            STORETYPE === "cleanpro" ? (
              <img
                className="d-block pb-3"
                src={insufficientToken}
                style={{ width: "64px", margin: "0 auto" }}
                alt=""
              />
            ) : null
          }
          <h2 className="text-center pb-2">{STORETYPE === "cleanpro" ? t("Insufficient balance") : t("Insufficient e-Tokens")}</h2>
          {STORETYPE === "cleanpro" ? <p className="text-center" style={{ fontSize: "16px", fontWeight: 300 }}>{t("Oops. You don't have enough balance to make the payment")}</p> : <p className="text-center">{t("Reload your e-token now?")}</p>}
          <div className={`d-flex pt-3 justify-content-center ${STORETYPE === "cleanpro" ? "flex-row-reverse" : ""}`}>
            <button id="cleanpro-topup-now" onClick={() => history.push("/user/reload")} className="btn btn-primary cleanpro-swal-btn washup-swal-btn mx-1 reload-token" style={{ backgroundColor: STORETYPE === "cleanpro" ? "#004FC1" : STORETYPE === "washup" ? "#004c64" : "#2778c4" }}>
              {STORETYPE === "cleanpro" ? t("Topup now") : t("Reload now!")}
            </button>
            <button id="cleanpro-cancel-topup" onClick={() => setInsufficientTokenModal(false)} className="btn btn-secondary cleanpro-swal-secondary-btn washup-swal-btn mx-1 cancel-reload-token">
              {STORETYPE === "cleanpro" ? t("Back") : t("Cancel")}
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        open={paymentMethodModal}
        onCancel={(e) => setPaymentMethodModal(false)}
        footer={null}
        closable={false}
        centered
      >
        <div className={theme}>
          <h2 className="text-center pb-2">{STORETYPE === "cleanpro" ? t("Payment methods") : t("Payment Method")}</h2>
          <p className="text-center">{STORETYPE === "cleanpro" ? t("Tap to proceed with your preferred mode of payment") : t("Would you like to pay with?")}</p>
          <div className="pt-3 d-flex flex-wrap justify-content-center">
            {
              user && user.tokenSetting ? (
                <button
                  type="button"
                  className="btn btn-primary cleanpro-swal-big-primary-btn washup-swal-btn e-wallet mx-1 my-1"
                  style={{ backgroundColor: STORETYPE === "washup" ? "#CFA82C" : "#2778c4" }}
                  onClick={(e) => handlePaymentMethod("eToken")}
                >
                  {STORETYPE === "cleanpro" ? t("Cleanpro Plus credits") : t("e-Token")}
                </button>
              ) : null
            }
            {
              machine.epayment ? (
                <button
                  type="button"
                  className="btn btn-primary cleanpro-swal-big-primary-btn washup-swal-btn e-token mx-1 my-1"
                  style={{ backgroundColor: STORETYPE === "washup" ? "#004c64" : "#67b668" }}
                  onClick={(e) => handlePaymentMethod("eWallet")}
                >
                  {STORETYPE === "cleanpro" ? `${t("E-wallet or credit/debit cards")} >` : t("E-Wallet")}
                </button>
              ) : null
            }
            {
              machine.duitnow || machine.rhbduitnow ? (
                <button
                  type="button"
                  className="btn btn-primary cleanpro-swal-big-primary-btn washup-swal-btn duitnow mx-1 my-1"
                  style={{ backgroundColor: STORETYPE === "washup" ? "#004c64" : "#67b668" }}
                  onClick={(e) => handlePaymentMethod("duitnow")}
                >
                  {t("Duitnow QR code")}
                </button>
              ) : null
            }
            {
              machine.terminal ? (
                <button
                  type="button"
                  className="btn btn-primary cleanpro-swal-big-primary-btn washup-swal-btn terminal mx-1 my-1"
                  style={{ backgroundColor: STORETYPE === "washup" ? "#004c64" : "#67b668" }}
                  onClick={(e) => handlePaymentMethod("paywave")}
                >
                  {t("Paywave")}
                </button>
              ) : null
            }
            <button
              type="button"
              className="btn btn-secondary cleanpro-swal-big-secondary-btn washup-swal-btn cancel-payment mx-1 my-1"
              style={{ backgroundColor: STORETYPE === "cleanpro" ? "#004FC1" : "#6C757D" }}
              onClick={(e) => setPaymentMethodModal(false)}
            >
              {t("Cancel")}
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        open={voucherReminderModal}
        onCancel={(e) => setVoucherReminderModal(false)}
        footer={null}
        closable={false}
        centered
      >
        <div className={theme}>
          <h2 className="text-center pb-2">{t("You haven't applied your voucher")}</h2>
          <p className="text-center">{t("Would you like to continue your payment without using your voucher?")}</p>
          <div className="d-flex pt-3 justify-content-center">
            <button
              type="button"
              className="btn btn-primary pay-without-voucher cleanpro-swal-secondary-btn washup-swal-btn mx-1"
              style={{ backgroundColor: STORETYPE === "washup" ? "#004c64" : "#2778c4" }}
              onClick={(e) => { setVoucherReminderModal(false); setPaymentMethodModal(true) }}
            >
              {t("Continue")}
            </button>

            <button
              type="button"
              className="btn btn-secondary apply-voucher cleanpro-swal-btn washup-swal-btn mx-1"
              onClick={(e) => { setVoucherReminderModal(false); setOpen(true) }}
            >
              {t("Apply voucher")}
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        open={paymentConfirmationModal}
        onCancel={(e) => setPaymentConfirmationModal(false)}
        footer={null}
        closable={false}
        centered
        maskClosable={false}
      >
        <div className={theme}>
          <div className="text-center">
            <i className="bi bi-question-circle" style={{ color: "#87adbd", fontSize: "70px" }}></i>
          </div>
          <h2 className="text-center pb-2">{t("Confirmation")}</h2>
          {paymentConfirmationText ? <div>{paymentConfirmationText}</div> : null}
          <div className={`d-flex pt-3 justify-content-center ${STORETYPE === "cleanpro" ? "flex-row-reverse" : ""}`}>
            {
              loading ? (
                <div className="text-center">
                  <div
                    className={`spinner-border text-${STORETYPE === "cuci" ? "warning" : "primary"}`}
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                <>
                  <button
                    type="button"
                    className="btn btn-primary cleanpro-swal-btn washup-swal-btn mx-1"
                    style={{ backgroundColor: STORETYPE === "washup" ? "#004c64" : "#2778c4" }}
                    onClick={(e) => confirmPayment()}
                  >
                    {STORETYPE === "cleanpro" ? t("Pay now") : t("OK")}
                  </button>

                  <button
                    type="button"
                    className="btn btn-secondary cleanpro-swal-secondary-btn washup-swal-btn mx-1"
                    onClick={(e) => setPaymentConfirmationModal(false)}
                  >
                    {STORETYPE === "cleanpro" ? t("Cancel") : t("No")}
                  </button>
                </>
              )
            }
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default WasherPayment;
