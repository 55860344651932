import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { CardGroup, Card } from "react-bootstrap";
import i18n from "i18next";
import { Trans } from "react-i18next";

import birthdayBanner from "../../../../assets/images/news/birthdayBanner.jpg";
import newUserBanner from "../../../../assets/images/news/newUserBanner.jpg";
import referralBanner from "../../../../assets/images/news/referralBanner.jpg";
import stampBanner from "../../../../assets/images/news/stampBanner.jpg";
import tokenBanner from "../../../../assets/images/news/tokenBanner.jpg";

// cp plus banners
import cleanproStampBanner from "../../../../assets/images/news/cleanproStampBanner.jpg";
import cleanproBirthdayBanner from "../../../../assets/images/news/cleanproBirthdayBanner.jpg";
// import cleanproJGMBanner from "../../../../assets/images/news/cleanproJGMBanner.jpg";
// import cleanproMigrationBanner from "../../../../assets/images/news/cleanproMigrationBanner.jpg";
// import cleanproNewUserBanner from "../../../../assets/images/news/cleanproNewUserBanner.jpg";
import cleanproReferralBanner from "../../../../assets/images/news/cleanproReferralBanner.jpg";
// import cleanproCuciEn from "../../../../assets/images/news/cleanproCuciEn.jpg";
// import cleanproCuciBm from "../../../../assets/images/news/cleanproCuciBm.jpg";
import cleanproLaundryPassBanner from "../../../../assets/images/news/cleanproLaundryPassBanner.jpeg";

// washup
import washupBirthdayPromotionBanner from "../../../../assets/images/news/washupBirthdayPromotionBanner.png";
import washupBirthdayNewsBanner from "../../../../assets/images/news/washupBirthdayNewsBanner.png";
import washupReferralPromotionBanner from "../../../../assets/images/news/washupReferralPromotionBanner.png";
import washupReferralNewsBanner from "../../../../assets/images/news/washupReferralNewsBanner.png";
import washupStampPromotionBanner from "../../../../assets/images/news/washupStampPromotionBanner.png";
import washupStampNewsBanner from "../../../../assets/images/news/washupStampNewsBanner.png";
import washupWelcomePromotionBanner from "../../../../assets/images/news/washupWelcomePromotionBanner.png";
import washupWelcomeNewsBanner from "../../../../assets/images/news/washupWelcomeNewsBanner.png";

const mySwal = withReactContent(Swal);

const STORETYPE = process.env.REACT_APP_STORETYPE;

class NewsSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      voucherSetting: null,
      referralSetting: null,
      news: [],
      inboxNews: false,
    };
  }

  componentDidMount() {
    if (this.props.operatorId) {
      axios
        .post("/api/user/getNews", { operatorId: this.props.operatorId })
        .then((res) => {
          this.setState({
            ...this.state,
            news: res.data,
            loading: false,
            voucherSetting: this.props.voucherSetting,
            referralSetting: this.props.referralSetting
          });
        })
        .catch((err) =>
          mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error")
        );
    }
  }

  render() {
    const renderStampBanner = () => {
      if (this.props.stamp) {
        if (this.props.combinedSetting) {
          if (this.state.voucherSetting && this.state.voucherSetting.filter(setting => setting.type === "Combined Stamp")[0].voucher_eligible) {
            return (
              <>
                {STORETYPE === "cleanpro" && this.props.inboxNews ? (
                  <Card
                    style={{ display: "flex", boxShadow: "none", border: "none", padding: "0.5rem 0rem" }}
                    className={this.props.page === "dashboard" ? "newsCard" : null}
                    onClick={() => this.props.history.push("/user/news/cleanproStamp")}
                  >
                    <Card.Body style={{ padding: "0.5rem 1rem" }}>
                      <Card.Text className="font-weight-bold">
                        {i18n.t("Collect 10 Stamps and Get Voucher")}
                      </Card.Text>
                      <Card.Text className="small-t pt-2">
                        {i18n.t("Claim a complimentary wash and dry for every 10 e-stamps collected! We value your loyalty and want to reward you for choosing Cleanpro Plus. Tap to learn more.")}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                ) : (
                  <Card
                    className={this.props.page === "dashboard" ? "newsCard" : null}
                    onClick={() => STORETYPE === "cleanpro" ? this.props.history.push("/user/moreDetails") : this.props.history.push("/user/news/stamp")}
                  >
                    <Card.Img variant="top" src={STORETYPE === "cleanpro" ? cleanproStampBanner : STORETYPE === "washup" && this.props.page === "dashboard" ? washupStampPromotionBanner : STORETYPE === "washup" && this.props.page !== "dashboard" ? washupStampNewsBanner : stampBanner} />
                    {
                      STORETYPE !== "washup" ? (
                        <Card.Body style={{ padding: "0.5rem 1rem" }}>
                          <Card.Text>{i18n.t("Learn More")}</Card.Text>
                        </Card.Body>
                      ) : null
                    }
                  </Card>
                )}
              </>
            );
          }
        } else {
          if (
            (this.state.voucherSetting &&
              this.state.voucherSetting.filter(setting => setting.type === "Washer Stamp")[0].voucher_eligible) ||
            this.state.voucherSetting.filter(setting => setting.type === "Dryer Stamp")[0].voucher_eligible
          ) {
            return (
              <>
                {STORETYPE === "cleanpro" && this.props.inboxNews ? (
                  <Card
                    style={{ display: "flex", boxShadow: "none", border: "none", padding: "0.5rem 0rem" }}
                    className={this.props.page === "dashboard" ? "newsCard" : null}
                    onClick={() => this.props.history.push("/user/news/cleanproStamp")}
                  >
                    <Card.Body style={{ padding: "0.5rem 1rem" }}>
                      <Card.Text className="font-weight-bold">
                        {i18n.t("Collect 10 Stamps and Get Voucher")}
                      </Card.Text>
                      <Card.Text className="small-t pt-2">
                        {i18n.t("Claim a complimentary wash and dry for every 10 e-stamps collected! We value your loyalty and want to reward you for choosing Cleanpro Plus. Tap to learn more.")}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                ) : (
                  <Card
                    className={this.props.page === "dashboard" ? "newsCard" : null}
                    onClick={() => STORETYPE === "cleanpro" ? this.props.history.push("/user/moreDetails") : this.props.history.push("/user/news/stamp")}
                  >
                    <Card.Img variant="top" src={STORETYPE === "cleanpro" ? cleanproStampBanner : STORETYPE === "washup" && this.props.page === "dashboard" ? washupStampPromotionBanner : STORETYPE === "washup" && this.props.page !== "dashboard" ? washupStampNewsBanner : stampBanner} />
                    {
                      STORETYPE !== "washup" ? (
                        <Card.Body style={{ padding: "0.5rem 1rem" }}>
                          <Card.Text>{i18n.t("Learn More")}</Card.Text>
                        </Card.Body>
                      ) : null
                    }
                  </Card>
                )}
              </>
            );
          }
        }
      }
    };

    const renderReferralBanner = () => {
      if (this.props.referral) {
        if (
          this.state.referralSetting &&
          ((this.state.referralSetting.stamp_eligible && this.props.stamp) ||
            (this.state.referralSetting.token_eligible && this.props.token) ||
            (this.state.referralSetting.point_eligible && this.props.loyalty_point) ||
            this.state.referralSetting.voucher_setting.voucher_eligible)
        ) {
          let voucherAmount = this.state.referralSetting.voucher_setting.voucher_amount
          if (!this.state.referralSetting.voucher_setting.voucher_eligible && this.state.referralSetting.token_eligible) {
            voucherAmount = this.state.referralSetting.token_amount
          }
          return (
            <>
              {STORETYPE === "cleanpro" && this.props.inboxNews ? (
                <Card
                  style={{ display: "flex", boxShadow: "none", border: "none", padding: "0.5rem 0rem" }}
                  className={this.props.page === "dashboard" ? "newsCard" : null}
                  onClick={() => this.props.history.push("/user/news/cleanproReferral")}
                >
                  <Card.Body style={{ padding: "0.5rem 1rem" }}>
                    <Card.Text className="font-weight-bold">
                      {i18n.t("Share Your Referral Code")}
                    </Card.Text>
                    <Card.Text className="small-t pt-2">
                      <Trans i18nKey="cleanproReferralShortDesc" count={voucherAmount}>Get free {{ voucherAmount }} credits when you share your referral code to friends and family after a successful transaction. Tap to learn more.</Trans>
                    </Card.Text>
                  </Card.Body>
                </Card>
              ) : (
                <Card
                  className={this.props.page === "dashboard" ? "newsCard" : null}
                  onClick={() => STORETYPE === "cleanpro" ? this.props.history.push("/user/news/cleanproReferral") : this.props.history.push("/user/news/referral")}
                >
                  <Card.Img variant="top" src={STORETYPE === "cleanpro" ? cleanproReferralBanner : STORETYPE === "washup" && this.props.page === "dashboard" ? washupReferralPromotionBanner : STORETYPE === "washup" && this.props.page !== "dashboard" ? washupReferralNewsBanner : referralBanner} />
                  {
                    STORETYPE !== "washup" ? (
                      <Card.Body style={{ padding: "0.5rem 1rem" }}>
                        <Card.Text>{i18n.t("Learn More")}</Card.Text>
                      </Card.Body>
                    ) : null
                  }
                </Card>
              )}
            </>
          );
        }
      }
    };

    const renderList = () => {
      if (this.state.loading) {
        return (
          <div className="text-center">
            <div
              className={STORETYPE === "cuci" ? "spinner-border text-warning" : "spinner-border text-primary"}
              role="status"
            >
              <span className="sr-only">Loading ...</span>
            </div>
          </div>
        );
      } else {
        if (this.props.inboxNews) {
          const birthdayVoucherAmount = this.state.voucherSetting.filter((setting) => setting.type === "Birthday")[0].voucher_amount
          return (
            <CardGroup>
              {this.state.news.filter(news => news.inbox).map((news, i) => {
                let newsLang = news.news_langs.filter(news_lang => news_lang.language === this.props.language)[0]
                if (news.end_date) {
                  if (new Date(news.end_date) >= new Date()) {
                    return (
                      <Card
                        style={{ display: "flex", boxShadow: "none", border: "none" }}
                        className={this.props.page === "dashboard" ? "newsCard" : null}
                        onClick={() => this.props.history.push(`/user/news/${news.id}`)}
                        key={i}
                      >
                        <Card.Body style={{ padding: "0.5rem 1rem" }}>
                          <Card.Text className="font-weight-bold">
                            {newsLang ? newsLang.title : news.title}
                          </Card.Text>
                          <Card.Text className="small-t pt-2" dangerouslySetInnerHTML={{ __html: newsLang ? newsLang.short_description : news.short_description }}></Card.Text>
                        </Card.Body>
                      </Card>
                    );
                  } else {
                    return null
                  }
                } else {
                  return (
                    <Card
                      style={{ display: "flex", boxShadow: "none", border: "none" }}
                      className={this.props.page === "dashboard" ? "newsCard" : null}
                      onClick={() => this.props.history.push(`/user/news/${news.id}`)}
                      key={i}
                    >
                      <Card.Body style={{ padding: "0.5rem 1rem" }}>
                        <Card.Text className="font-weight-bold">
                          {newsLang ? newsLang.title : news.title}
                        </Card.Text>
                        <Card.Text className="small-t pt-2" dangerouslySetInnerHTML={{ __html: newsLang ? newsLang.short_description : news.short_description }}></Card.Text>
                      </Card.Body>
                    </Card>
                  );
                }
              })}
              {/* {
                STORETYPE === "cleanpro" && this.props.operatorId === "rrton9vindhsablnnusth287" ? (
                  <Card
                    style={{ display: "flex", boxShadow: "none", border: "none", padding: "0.5rem 0rem" }}
                    className={this.props.page === "dashboard" ? "newsCard" : null}
                    onClick={() => this.props.history.push("/user/news/cleanprocope")}
                  >
                    <Card.Body style={{ padding: "0.5rem 1rem" }}>
                      <Card.Text className="font-weight-bold">
                        {i18n.t("RM10 OFF Welcome Voucher")}
                      </Card.Text>
                      <Card.Text className="small-t pt-2">
                        {i18n.t("Welcome to the Cleanpro family, here is an appreciation voucher from us. Thank you for attending the COPE Investors Conference.")}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                ) : null
              } */}
              {/* {
                STORETYPE === "cleanpro" ? (
                  <Card
                    style={{ display: "flex", boxShadow: "none", border: "none", padding: "0.5rem 0rem" }}
                    className={this.props.page === "dashboard" ? "newsCard" : null}
                    onClick={() => this.props.history.push("/user/news/cleanproPayMigrationNotice")}
                  >
                    <Card.Body style={{ padding: "0.5rem 1rem" }}>
                      <Card.Text className="font-weight-bold">
                        {i18n.t("Cleanpro Pay Migration Notice")}
                      </Card.Text>
                      <Card.Text className="small-t pt-2">
                        {i18n.t("Dear valued Cleanpro customers, please be informed that as of 22 June 2023, the Cleanpro Pay app will be upgraded and and no longer available for use. Tap to learn more.")}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                ) : null
              } */}
              {/* {
                STORETYPE === "cleanpro" ? (
                  <Card
                    style={{ display: "flex", boxShadow: "none", border: "none", padding: "0.5rem 0rem" }}
                    className={this.props.page === "dashboard" ? "newsCard" : null}
                    onClick={() => this.props.history.push("/user/news/welcomeToCleanproPlus")}
                  >
                    <Card.Body style={{ padding: "0.5rem 1rem" }}>
                      <Card.Text className="font-weight-bold">
                        {i18n.t("Welcome to Cleanpro Plus")}
                      </Card.Text>
                      <Card.Text className="small-t pt-2">
                        {i18n.t("Only scan, set & pay! An easier, more convenient way to pay with Cleanpro Plus! No need to download an app. Enjoy more rewards when you use Cleanpro Plus today! Tap to learn more.")}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                ) : null
              } */}
              {/* {
                STORETYPE === "cleanpro" && new Date() < new Date(2023, 8, 16) ? (
                  <Card
                    style={{ display: "flex", boxShadow: "none", border: "none", padding: "0.5rem 0rem" }}
                    className={this.props.page === "dashboard" ? "newsCard" : null}
                    onClick={() => this.props.history.push("/user/news/cuciharihari")}
                  >
                    <Card.Body style={{ padding: "0.5rem 1rem" }}>
                      <Card.Text className="font-weight-bold">
                        {i18n.t("Cuci Hari-Hari Bersama Cleanpro!")}
                      </Card.Text>
                      <Card.Text className="small-t pt-2">
                        {i18n.t("Ready for a challenge?")}<br />
                        {i18n.t("Here's one for you - all you need to do is wash your laundry 10 times in 20 days at participating Cleanpro outlets. Stand a chance to win a Limited Edition Cleanpro Designer Laundry Bag worth up to RM40!")}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                ) : null
              } */}
              {/* {
                STORETYPE === "cleanpro" && new Date() < new Date(2023, 7, 1) ? (
                  <Card
                    style={{ display: "flex", boxShadow: "none", border: "none", padding: "0.5rem 0rem" }}
                    className={this.props.page === "dashboard" ? "newsCard" : null}
                    onClick={() => this.props.history.push("/user/news/jawabGerentiMenang")}
                  >
                    <Card.Body style={{ padding: "0.5rem 1rem" }}>
                      <Card.Text className="font-weight-bold">
                        {i18n.t("Jawab & Gerenti Menang")}
                      </Card.Text>
                      <Card.Text className="small-t pt-2">
                        {i18n.t("Answer simple questions and win credits for your next wash or dry! This contest is only available for selected outlets only. Tap to learn more.")}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                ) : null
              } */}
              {
                this.state.voucherSetting.filter((setting) => setting.type === "Register")[0].voucher_eligible ? (
                  <Card
                    style={{ display: "flex", boxShadow: "none", border: "none", padding: "0.5rem 0rem" }}
                    className={this.props.page === "dashboard" ? "newsCard" : null}
                    onClick={() => this.props.history.push("/user/news/cleanproWelcome")}
                  >
                    <Card.Body style={{ padding: "0.5rem 1rem" }}>
                      <Card.Text className="font-weight-bold">
                        {i18n.t("Welcome Voucher")}
                      </Card.Text>
                      <Card.Text className="small-t pt-2">
                        {i18n.t("Welcome to the Cleanpro Plus family! This first gift is just the beginning. Stay tuned for a series of exciting promotions and campaigns, exclusively for Cleanpro Plus users.")}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                ) : null
              }
              {renderReferralBanner()}
              {
                this.state.voucherSetting.filter((setting) => setting.type === "Birthday")[0].voucher_eligible ? (
                  <Card
                    style={{ display: "flex", boxShadow: "none", border: "none", padding: "0.5rem 0rem" }}
                    className={this.props.page === "dashboard" ? "newsCard" : null}
                    onClick={() => this.props.history.push("/user/news/cleanproBirthday")}
                  >
                    <Card.Body style={{ padding: "0.5rem 1rem" }}>
                      <Card.Text className="font-weight-bold">
                        {i18n.t("Birthday Gift for You")}
                      </Card.Text>
                      <Card.Text className="small-t pt-2">
                        <Trans i18nKey="cleanproBirthdayShortDesc" count={birthdayVoucherAmount}>Redeem {{ birthdayVoucherAmount }} credits birthday voucher on your special day with Cleanpro Plus. Tap to learn more.</Trans>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                ) : null
              }
              {renderStampBanner()}
            </CardGroup>
          );
        } else
          return (
            <CardGroup
              className={this.props.page === "dashboard" ? "newsSlider" : null}
            >

              {this.state.news.filter(news => news.promotion).map((news, i) => {
                if (news.end_date) {
                  if (new Date(news.end_date) >= new Date()) {
                    return (
                      <Card
                        className={this.props.page === "dashboard" ? "newsCard" : null}
                        onClick={() => this.props.history.push(`/user/news/${news.id}`)}
                        key={i}
                      >
                        <Card.Img variant="top" src={news.banner} />
                        {
                          STORETYPE !== "washup" ? (
                            <Card.Body style={{ padding: "0.5rem 1rem" }}>
                              <Card.Text>{i18n.t("Learn More")}</Card.Text>
                            </Card.Body>
                          ) : null
                        }
                      </Card>
                    );
                  } else {
                    return null
                  }
                } else {
                  return (
                    <Card
                      className={this.props.page === "dashboard" ? "newsCard" : null}
                      onClick={() => this.props.history.push(`/user/news/${news.id}`)}
                      key={i}
                    >
                      <Card.Img variant="top" src={news.banner} />
                      {
                        STORETYPE !== "washup" ? (
                          <Card.Body style={{ padding: "0.5rem 1rem" }}>
                            <Card.Text>{i18n.t("Learn More")}</Card.Text>
                          </Card.Body>
                        ) : null
                      }
                    </Card>
                  );
                }
              })}

              {STORETYPE === "cleanpro" && new Date() < new Date(2025, 4, 1) ? (
                <Card
                  className={this.props.page === "dashboard" ? "newsCard" : null}
                  onClick={() => this.props.history.push("/user/news/cleanproLaundryPass")}
                >
                  <Card.Img variant="top" src={cleanproLaundryPassBanner} />
                  <Card.Body style={{ padding: "0.5rem 1rem" }}>
                    <Card.Text>{i18n.t("Learn More")}</Card.Text>
                  </Card.Body>
                </Card>
              ) : null}

              {/* {STORETYPE === "cleanpro" ? (
                <Card
                  className={this.props.page === "dashboard" ? "newsCard" : null}
                  onClick={() => this.props.history.push("/user/news/cleanproPayMigrationNotice")}
                >
                  <Card.Img variant="top" src={cleanproMigrationBanner} />
                  <Card.Body style={{ padding: "0.5rem 1rem" }}>
                    <Card.Text>{i18n.t("Learn More")}</Card.Text>
                  </Card.Body>
                </Card>
              ) : null} */}

              {/* {STORETYPE === "cleanpro" ? (
                <Card
                  className={this.props.page === "dashboard" ? "newsCard" : null}
                  onClick={() => this.props.history.push("/user/news/welcomeToCleanproPlus")}
                >
                  <Card.Img variant="top" src={cleanproNewUserBanner} />
                  <Card.Body style={{ padding: "0.5rem 1rem" }}>
                    <Card.Text>{i18n.t("Learn More")}</Card.Text>
                  </Card.Body>
                </Card>
              ) : null} */}

              {/* {STORETYPE === "cleanpro" && new Date() < new Date(2023, 8, 16) ? (
                <Card
                  className={this.props.page === "dashboard" ? "newsCard" : null}
                  onClick={() => this.props.history.push("/user/news/cuciharihari")}
                >
                  <Card.Img variant="top" src={this.props.language === "ms" ? cleanproCuciBm : cleanproCuciEn} />
                  <Card.Body style={{ padding: "0.5rem 1rem" }}>
                    <Card.Text>{i18n.t("Learn More")}</Card.Text>
                  </Card.Body>
                </Card>
              ) : null} */}

              {renderStampBanner()}

              {/* {STORETYPE === "cleanpro" && new Date() < new Date(2023, 7, 1) ? (
                <Card
                  className={this.props.page === "dashboard" ? "newsCard" : null}
                  onClick={() => this.props.history.push("/user/news/jawabGerentiMenang")}
                >
                  <Card.Img variant="top" src={cleanproJGMBanner} />
                  <Card.Body style={{ padding: "0.5rem 1rem" }}>
                    <Card.Text>{i18n.t("Learn More")}</Card.Text>
                  </Card.Body>
                </Card>
              ) : null} */}

              {STORETYPE !== "cleanpro" && this.state.voucherSetting.filter((setting) => setting.type === "Register")[0].voucher_eligible ? (
                <Card
                  className={this.props.page === "dashboard" ? "newsCard" : null}
                  onClick={() => this.props.history.push("/user/news/newUser")}
                >
                  <Card.Img variant="top" src={STORETYPE === "washup" && this.props.page === "dashboard" ? washupWelcomePromotionBanner : STORETYPE === "washup" && this.props.page !== "dashboard" ? washupWelcomeNewsBanner : newUserBanner} />
                  {
                    STORETYPE !== "washup" ? (
                      <Card.Body style={{ padding: "0.5rem 1rem" }}>
                        <Card.Text>{i18n.t("Learn More")}</Card.Text>
                      </Card.Body>
                    ) : null
                  }
                </Card>
              ) : null}

              {renderReferralBanner()}

              {this.state.voucherSetting.filter((setting) => setting.type === "Birthday")[0].voucher_eligible ? (
                <Card
                  className={this.props.page === "dashboard" ? "newsCard" : null}
                  onClick={() => STORETYPE === "cleanpro" ? this.props.history.push("/user/news/cleanproBirthday") : this.props.history.push("/user/news/birthday")}
                >
                  <Card.Img variant="top" src={STORETYPE === "cleanpro" ? cleanproBirthdayBanner : STORETYPE === "washup" && this.props.page === "dashboard" ? washupBirthdayPromotionBanner : STORETYPE === "washup" && this.props.page !== "dashboard" ? washupBirthdayNewsBanner : birthdayBanner} />
                  {
                    STORETYPE !== "washup" ? (
                      <Card.Body style={{ padding: "0.5rem 1rem" }}>
                        <Card.Text>{i18n.t("Learn More")}</Card.Text>
                      </Card.Body>
                    ) : null
                  }
                </Card>
              ) : null}

              {this.props.token && (STORETYPE !== "cleanpro" && STORETYPE !== "washup") ? (
                <Card
                  className={this.props.page === "dashboard" ? "newsCard" : null}
                  onClick={() => this.props.history.push("/user/news/token")}
                >
                  <Card.Img variant="top" src={tokenBanner} />
                  <Card.Body style={{ padding: "0.5rem 1rem" }}>
                    <Card.Text>{i18n.t("Learn More")}</Card.Text>
                  </Card.Body>
                </Card>
              ) : null}

            </CardGroup>
          );
      }
    };

    return <div className="mb-5">{renderList()}</div>;
  }
}

const mapStateToProps = (state) => {
  return {
    operatorId: state.user.operatorId,
    token: state.user.user?.tokenSetting,
    combinedSetting: state.user.user?.combinedSetting,
    specificStamp: state.user.user?.specificStamp,
    referral: state.user.user?.referral,
    stamp: state.user.user?.stamp,
    stampTransactionAmount: state.user.user?.stampTransactionAmount,
    language: state.user.language,
    referralSetting: state.user.user?.referralSetting,
    voucherSetting: state.user.user?.voucher_setting,
    loyalty_point: state.user.user?.loyalty_point
  };
};

export default connect(mapStateToProps, null)(NewsSlider);
